import type { GraphQLFormattedError } from "graphql/error/index.js";

export interface AppSyncGraphQLError extends GraphQLFormattedError {
	readonly errorType?: string;
	readonly errorInfo?: unknown;
	readonly data?: unknown;
}

export class AppSyncError extends Error {
	constructor(public readonly errors: readonly AppSyncGraphQLError[]) {
		super(errors.map((error) => error.message).join(", "));
	}
}
