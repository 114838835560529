import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41')
];

export const server_loads = [];

export const dictionary = {
		"/(admin)": [4,[2]],
		"/(admin)/career-applications": [5,[2]],
		"/(admin)/career-applications/[id]": [6,[2]],
		"/(admin)/careers": [7,[2]],
		"/(admin)/careers/add": [9,[2]],
		"/(admin)/careers/[id]": [8,[2]],
		"/(admin)/case-studies": [10,[2]],
		"/(admin)/case-studies/add": [12,[2]],
		"/(admin)/case-studies/sort": [13,[2]],
		"/(admin)/case-studies/[id]": [11,[2]],
		"/(cognito)/confirm-recover": [37,[3]],
		"/(admin)/contacts": [14,[2]],
		"/(admin)/contacts/add": [16,[2]],
		"/(admin)/contacts/sort": [17,[2]],
		"/(admin)/contacts/[id]": [15,[2]],
		"/(admin)/documents": [18,[2]],
		"/(admin)/documents/add": [20,[2]],
		"/(admin)/documents/sort": [21,[2]],
		"/(admin)/documents/[id]": [19,[2]],
		"/(cognito)/login": [38,[3]],
		"/(cognito)/new-password": [39,[3]],
		"/(admin)/news": [22,[2]],
		"/(admin)/news/add": [24,[2]],
		"/(admin)/news/[id]": [23,[2]],
		"/(admin)/oauth2": [25,[2]],
		"/(admin)/product-group-banners": [26,[2]],
		"/(admin)/product-group-banners/add": [28,[2]],
		"/(admin)/product-group-banners/[id]": [27,[2]],
		"/(admin)/product-groups": [29,[2]],
		"/(admin)/product-groups/add": [31,[2]],
		"/(admin)/product-groups/sort": [32,[2]],
		"/(admin)/product-groups/[id]": [30,[2]],
		"/(cognito)/recover": [40,[3]],
		"/(admin)/settings": [33,[2]],
		"/(admin)/users": [34,[2]],
		"/(admin)/users/add": [36,[2]],
		"/(admin)/users/[id]": [35,[2]],
		"/[...path]": [41]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';